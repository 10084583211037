@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply dark:bg-neutral-900 dark:text-white;
  font-family: "Poppins", sans-serif;
}

.button {
  @apply relative px-6 py-3 text-lg font-semibold transition border-2 rounded hover:before:right-0 hover:before:opacity-0;
}

.button::before {
  content: "";
  transition: 0.3s ease !important;
  @apply w-6 h-0.5 absolute block -right-3 top-1/2 transform -translate-y-1/2 pointer-events-none rounded;
}

.button-blue {
  @apply border-default text-default before:bg-default hover:bg-default hover:text-white;
}

.button-white {
  @apply text-white border-white before:bg-white hover:bg-white hover:text-default;
}

h4 {
  @apply text-lg font-semibold;
}

h4::before {
  content: "";
  @apply relative inline-block w-4 h-4 rounded-full top-0.5 ml-4 -left-4 bg-default;
}

h3 {
  @apply pb-2 md:text-lg text-default;
}

.c-container {
  @apply max-w-6xl px-4 py-4 mx-auto md:py-6 lg:py-8;
}

.careers-page h3 {
  @apply py-4 font-semibold first-of-type:pt-0;
}

.careers-page ul > li::before {
  content: "";
  @apply relative inline-block w-2 h-2 ml-2 rounded-full top-[-0.05rem] -left-2 bg-default;
}

.footer-ul > li > a {
  @apply block transition hover:text-default;
}

.footer-ul > li > span {
  @apply block pb-2 mt-4 font-semibold md:text-lg;
}
